import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import {MatPaginator, MatPaginatorModule} from '@angular/material/paginator';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { aquaProductData } from './mock/aqua-product-mock';
import { veterinaryProductData } from './mock/veterinary-product-mock';
import { canineProductData } from './mock/canine-product-mock';
import { humanProductData } from './mock/human-product-mock';
import { animalProductData } from './mock/animal-product-mock';
// import * as XLSX from 'xlsx';
import { ActivatedRoute } from '@angular/router';

export interface productElemnets {
  brandName: string;
  active_ingredient: string[];
  theropetic_segment: string[];
  pack_details: string[];
}

@Component({
  selector: 'app-product-component',
  templateUrl: './product-component.component.html',
  styleUrls: ['./product-component.component.css'],
})


export class ProductComponentComponent implements OnInit {

  constructor(public activateRoute: ActivatedRoute) { 

  }
  productid :any;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  productlist: string[] = ['VETERINARY PHARMACEUTICALS', 'ANIMAL FEED SUPPLEMENTS', 'AQUACULTURE', 'CANINE'];
  // productlist: string[] = ['VETERINARY PHARMACEUTICALS', 'ANIMAL FEED SUPPLEMENTS', 'AQUACULTURE', 'CANINE', 'HUMAN'];


  brandslist: string[] = [];

  products = new FormControl();
  brands = new FormControl();

  displayedColumns: string[] = ['brandName', 'active_ingredient', 'theropetic_segment', 'pack_details'];

  // dataSource = new MatTableDataSource(humanProductData);

  allProductData = [];
  showBrandList : boolean = false;

    // MatPaginator Inputs
    length= 100;
    pageSize = 10;

  ngOnInit(): void {
    this.productid = this.activateRoute.snapshot.paramMap.get('id');
    this.scrollToTop();
    console.log('productid=----',this.productid);
    if(this.productid == null) {
      this.dataSource.paginator = this.paginator;
      this.allProductData.push(...veterinaryProductData);
      this.allProductData.push(...aquaProductData);
      this.allProductData.push(...canineProductData);
      this.allProductData.push(...humanProductData);
      this.allProductData.push(...animalProductData);
      console.log("this.allProductData----",this.allProductData);  
    }else{
      this.showBrandList = true;
      if(this.productid == 1) {
        this.allProductData.push(...veterinaryProductData);
        console.log("product 1",this.allProductData.length);
        // this.form.set('product').value = "VETERINARY PHARMACEUTICALS";
        veterinaryProductData.forEach((elements) => {
          this.brandslist.push(elements.brandName);
        })
      }else if(this.productid == 2){
        this.allProductData.push(...animalProductData);
        console.log("product 2",this.allProductData.length);
        animalProductData.forEach((elements) => {
          this.brandslist.push(elements.brandName);
        })
        
      }else if(this.productid == 3){
        this.allProductData.push(...aquaProductData);
        console.log("product 3",this.allProductData.length);
        aquaProductData.forEach((elements) => {
          this.brandslist.push(elements.brandName);
        })
        
      }else if(this.productid == 4){
        this.allProductData.push(...canineProductData);
        console.log("product 4",this.allProductData.length);
        canineProductData.forEach((elements) => {
          this.brandslist.push(elements.brandName);
        })
        
      }else if(this.productid == 5){
        this.allProductData.push(...humanProductData);
        console.log("product 5",this.allProductData.length);
        humanProductData.forEach((elements) => {
          this.brandslist.push(elements.brandName);
        })
      }
    }

  }

  dataSource = new MatTableDataSource(this.allProductData);

  form = new FormGroup({
    products: new FormControl('', Validators.required),
    brands: new FormControl('', Validators.required)
  });

  onSelectProduct(value) {
    console.log("onselect ---", value);
    this.showBrandList = true;
    if (value == "VETERINARY PHARMACEUTICALS") {
      this.brandslist = [];
      this.form.value.brands = "";
      veterinaryProductData.forEach((elements) => {
        this.brandslist.push(elements.brandName);
      })
    } else if (value == "ANIMAL FEED SUPPLEMENTS") {
      this.brandslist = [];
      this.form.value.brands = "";
      animalProductData.forEach((elements) => {
        this.brandslist.push(elements.brandName);
      })
    } else if (value == "AQUACULTURE") {
      this.brandslist = [];
      this.form.value.brands = "";
      aquaProductData.forEach((elements) => {
        this.brandslist.push(elements.brandName);
      })
    } else if (value == "CANINE") {
      this.brandslist = [];
      this.form.value.brands = "";
      canineProductData.forEach((elements) => {
        this.brandslist.push(elements.brandName);
      })
    } else if (value == "HUMAN") {
      this.brandslist = [];
      this.form.value.brands = "";
      humanProductData.forEach((elements) => {
        this.brandslist.push(elements.brandName);
      })
    }
  }


  submit() {
    console.log("in sbmit", this.form.value);
    var a = [];
    if (this.form.value.products == "VETERINARY PHARMACEUTICALS" && this.brandslist.length >1) {   
      if (this.form.value.brands == "" || this.form.value.brands == null) {        
        this.dataSource = new MatTableDataSource(veterinaryProductData);
      } else {    
        a.push(veterinaryProductData.find(items => items.brandName == this.form.value.brands));
        this.dataSource = new MatTableDataSource(a);
      }
    } else if (this.form.value.products == "ANIMAL FEED SUPPLEMENTS") {
      if (this.form.value.brands == "" || this.form.value.brands == null) {
        this.dataSource = new MatTableDataSource(animalProductData);
      } else {
        a.push(animalProductData.find(items => items.brandName == this.form.value.brands));
        this.dataSource = new MatTableDataSource(a);
      }
    } else if (this.form.value.products == "AQUACULTURE") {
      if (this.form.value.brands == "" || this.form.value.brands == null) {
        this.dataSource = new MatTableDataSource(aquaProductData);
      } else {
        a.push(aquaProductData.find(items => items.brandName == this.form.value.brands));
        this.dataSource = new MatTableDataSource(a);
      }
    } else if (this.form.value.products == "CANINE") {
      if (this.form.value.brands == "" || this.form.value.brands == null) {
        this.dataSource = new MatTableDataSource(canineProductData);
      } else {
        a.push(canineProductData.find(items => items.brandName == this.form.value.brands));
        this.dataSource = new MatTableDataSource(a);
      }
    } else if (this.form.value.products == "HUMAN") {
      if (this.form.value.brands == "" || this.form.value.brands == null) {
        this.dataSource = new MatTableDataSource(humanProductData);
      } else {
        a.push(humanProductData.find(items => items.brandName == this.form.value.brands));
        this.dataSource = new MatTableDataSource(a);
      }
    }
  }

  scrollToTop() {
    (function smoothscroll() {
        var currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
        if (currentScroll > 0) {
            window.requestAnimationFrame(smoothscroll);
            window.scrollTo(0, currentScroll - (currentScroll / 8));
        }
    })();
}

  // exportexcel(): void
  // {
  //   /* pass here the table id */
  //   let element = document.getElementById('excel-table');
  //   const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element);
 
  //   /* generate workbook and add the worksheet */
  //   const wb: XLSX.WorkBook = XLSX.utils.book_new();
  //   XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
 
  //   /* save to file */  
  //   XLSX.writeFile(wb, this.form.value.products +'.XLSX');
 
  // }
}
