<!DOCTYPE html>
<html lang="en">
<body>
  <!-- <div id="topheader">
    <div class="container-fluid">
      <nav class="navbar navbar-light navbar-expand-md ">
        <a class="navbar-brand" routerLink="/home">
          <img src="assets/excellar01logo.jpg" alt="CompanyLogo" width="160px" height="70px">
        </a>
        <button data-toggle="collapse" data-target="#navbarToggler" type="button" class="navbar-toggler">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="navbar-collapse collapse" id="navbarToggler" >
          <ul class="navbar-nav">
            <li><a routerLink="/home" >HOME</a></li>
            <li><a routerLink="/aboutus" >ABOUT US</a></li>
            <li><a routerLink="/products" >PRODUCTS</a></li>
            <li><a routerLink="/contactus" >CONTACT US</a></li>
          </ul>       
        </div>
      </nav>
    </div>
  </div>   -->

  <div class="container-fluid">
    <nav class="navbar navbar-expand-md navbar-light fixed-topbg-dark">
      <a class="navbar-brand" href="home">
        <img src="assets/excellar01logo.jpg" alt="CompanyLogo" width="160px" height="70px">
      </a>
      <button data-toggle="collapse" data-target="#navbarCollapse" type="button" class="navbar-toggler">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse " id="navbarCollapse">
        <ul class="navbar-nav ">
          <li class="nav-item">
            <a class="nav-link js scroll-trigger" href="home">HOME <span class="sr-only">(current)</span></a>
          </li>
          <li class="nav-item">
            <a class="nav-link js-scroll-trigger" href="aboutus">ABOUT US</a>
          </li>
          <li class="nav-item">
            <a class="nav-link js-scroll-trigger" href="products">PRODUCTS</a>
          </li>
          <li class="nav-item">
            <a class="nav-link js-scroll-trigger" href="contactus">CONTACT US</a>
          </li>
        </ul>
      </div>
    </nav>
  </div>

  <!-- <div class="container-fluid">
  <nav class="navbar navbar-expand-md navbar-light fixed-topbg-dark">
    <a class="navbar-brand" href="home">
      <img src="assets/excellar01logo.jpg" alt="CompanyLogo" width="160px" height="70px">
    </a>
    <button data-toggle="collapse" data-target="#navbarCollapse" type="button" class="navbar-toggler">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarCollapse">
      <ul class="navbar-nav mr-auto">
        <li class="nav-item active">
          <a class="nav-link js-scroll-trigger" href="home">HOME<span class="sr-only">(current)</span></a>
        </li>
        <li class="nav-item">
          <a class="nav-link js-scroll-trigger" href="aboutus" >ABOUT US</a>
        </li>
        <li class="nav-item">
          <a class="nav-link js-scroll-trigger" href="products" >PRODUCTS</a>
        </li>
        <li class="nav-item ">
          <a class="nav-link js-scroll-trigger" href="contactus" >CONTACT US</a>
        </li>
        </ul>
    </div>
  </nav>
  </div> -->


<script type="text/javascript">
    $('.js-scroll-trigger').click(function(){
        //  $('.navbar-collapse .navbar-nav').find('li.active').removeClass('active');
        //  $(this).parent('li').addClass('active');
         $('.navbar-collapse').collapse('hide');
    });
</script>      
</body>

</html>

