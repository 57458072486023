import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  baseURL: any = 'https://excellar/callemailapi/';

  constructor(private http: HttpClient) { }

  private getHeader(): HttpHeaders {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('accept', 'application/json');
    headers = headers.append('Access-Control-Allow-Origin', '*');
    headers = headers.append('Content-Type', 'application/json');
    return headers;
  }

  public get(endPoint: any) {
    const headers = this.getHeader();
    return this.http.get(`${this.baseURL + endPoint}`);
  }
}
