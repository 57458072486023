export const canineProductData = [
    {
        "brandId": 1,
        "brandName": "CARINENUT",
        "active_ingredient": ["Ketoconazole I.P. 2 % w/v", "Lotion base q.s."],
        "theropetic_segment": ['ANTI FUNGAL'],
        "pack_details": ["50 ml"]
    },
    {
        "brandId": 2,
        "brandName": "CANOMFORT PET",
        "active_ingredient": ["Each enteric coated canomfort Pet tablet contains : Esomeprazole Magnesium Trihydrate I.P Eq.to Esomeprazole -20mg"],
        "theropetic_segment": ['PROTON PUMP INHIBITOR'],
        "pack_details": ["10 Strip"]
    },
    {
        "brandId": 3,
        "brandName": "ENDECTIN TABLET",
        "active_ingredient": ["Each tablet contains: Ivermectin I.P.(Vet) 10 mg. Excipients q.s."],
        "theropetic_segment": ['PARASITICIDE'],
        "pack_details": ["10 mg Strip"]
    },
    {
        "brandId": 4,
        "brandName": "ENDECTIN - I INJECTION",
        "active_ingredient": ["Each ml. contains: Ivermectin I.P.(Vet) 10 mg.", "Benzyl alcohol I.P. 1.5% v/v (As Preservative)", "Propylene Glycol I.P. q.s."],
        "theropetic_segment": ['PARASITICIDE'],
        "pack_details": ["1 ml"]
    },
    {
        "brandId": 5,
        "brandName": "EDOZY-N",
        "active_ingredient": ["Each vial Contains : Ceftriaxone Sodium I.P. (Sterile) 500 MG eq. to Ceftriaxone", "Tazobactam Sodium (Sterile) 62.50 mg eq. to Tazobactam", "Each FFS Ampoule contains : Sterile Water For Injection I.P. 5 ml"],
        "theropetic_segment": ['ANTIBIOTIC'],
        "pack_details": ["562.5 mg Vial"]
    },
    {
        "brandId": 6,
        "brandName": "EXLDOXY PET TABLET",
        "active_ingredient": ["Each Uncoated tablet contains : Doxycycline Hydrochloride I.P. 100 mg.", "Lactic acid bacillus 5 billion spores."],
        "theropetic_segment": ['ANTIBIOTIC'],
        "pack_details": ["10 × 10 Tab"]
    },
    {
        "brandId": 7,
        "brandName": "NINEONDAN PET TABLET",
        "active_ingredient": ["Each uncoated tablet contains: Ondansetron Hydrochloride I.P Eq. to Ondansetron - 4mg."],
        "theropetic_segment": ["ANTIEMETIC"],
        "pack_details": ["10 × 10 Tab"]
    },
    {
        "brandId": 8,
        "brandName": "SUCRALCAN SYRUP",
        "active_ingredient": ["Each 10ml contain : Sucralfate I.P - 1000 m.g", "Oxetacaine B.P - 20 mg"],
        "theropetic_segment": ['ANTI ULCERANT'],
        "pack_details": ["60 ml / 200 ml"]
    },
    {
        "brandId": 9,
        "brandName": "AMPIXEL PET SYRUP",
        "active_ingredient": ["Each 15ml contains: Grape Seed Extract 50 mg", "L-Lysine 50 mg", "L-Leucine 50 mg", "L-Threonine 50 mg", "L-Methionine 20 mg", "L-Valine 20 mg", "L-Isoleucine 20 mg", "L-Phenylalanine 20 mg", "L-Histidine 15 mg", "L-Arginine 10 mg", "L-Taurine 10 mg", "L-Tryptophan 10 mg", "L-Carnitine 10 mg", "Tyrosine 10 mg", "Vitamin C 40 mg", "Vitamin B3 25 mg", "Vitamin B1 5.5 mg", "Vitamin B5 5 mg", "Vitamin B2 3 mg", "Vitamin B6 1.2 mg", "Vitamin B9 0.8 mg", "Vitamin B12 2.5 mcg", "Vitamin D3 50 IU", "Vitamin E 5 IU", "Appropriate overages added to compensate the loss on storage"],
        "theropetic_segment": ['NUTRICEUTICAL'],
        "pack_details": ["200 ml"]
    },
    {
        "brandId": 10,
        "brandName": "AMPIXEL - Z SYRUP",
        "active_ingredient": ["Each 15ml contains: Grape Seed Extract 50 mg", "L-Lysine 50 mg", "L-Leucine 50 mg", "L-Threonine 50 mg", "L-Methionine 20 mg", "L-Valine 20 mg", "L-Isoleucine 20 mg", "L-Phenylalanine 20 mg", "L-Histidine 15 mg", "L-Arginine 10 mg", "L-Taurine 10 mg", "L-Tryptophan 10 mg", "L-Carnitine 10 mg", "Tyrosine 10 mg", "Vitamin C 40 mg", "Vitamin B3 25 mg", "Vitamin B1 5.5 mg", "Vitamin B5 5 mg", "Vitamin B2 3 mg", "Vitamin B6 1.2 mg", "Vitamin B9 0.8 mg", "Vitamin B12 2.5 mcg", "Vitamin D3 50 IU", "Vitamin E 5 IU", "Appropriate overages added to compensate the loss on storage"],
        "theropetic_segment": ["NUTRICEUTICAL"],
        "pack_details": ["5 ltr"]
    },
    {
        "brandId": 11,
        "brandName": "CALPHOS MD TABLET",
        "active_ingredient": ["Each Tablet contains: Calcium 630 mg", "Phosphorus 500 mg", "Vitamin C 10 mg", "Vitamin B6 1 mg", "Calcitriol 0.15 mcg", "Vitamin A 1000 I.U."],
        "theropetic_segment": ['NUTRICEUTICAL'],
        "pack_details": ["10 Tablets"]
    },
    {
        "brandId": 12,
        "brandName": "DIGESTIVE PET DROP",
        "active_ingredient": ["Each 5ml contains: Alpha amylase 50mg", "Papain 6.0mg", "Cinnamon Oil 0.2mg", "Cardamom Oil 0.4mg ", "Caraway Oil 0.4mg", "Dill Oil 0.05ml", "Fennel Oil 0.0025ml", "Base Q.S."],
        "theropetic_segment": ['NUTRICEUTICAL'],
        "pack_details": ["30 ml & 100 ml"]
    },
    {
        "brandId": 13,
        "brandName": "FEROLIV PET SYRUP",
        "active_ingredient": ["Each 5ml contains: Ferrous ascorbate 12.5 mg", "Taurine 10 mg", "L-Carnitine 10 mg", "Copper 0.1 mg", "Vitamin B6 2 mg", "Vitamin B2 1.7 mg", "Vitamin B1 1.4 mg", "Vitamin B12 15 mcg", "Vitamin B3 10 mg", "Vitamin B5 5 mg", "Vitamin K 1 mcg", "Vitamin A 1000 IU", "Vitamin D3 100 IU", "Vitamin E 10 IU", "Vitamin B9 0.25 mg"],
        "theropetic_segment": ['NUTRICEUTICAL'],
        "pack_details": ["200 ml"]
    },
    {
        "brandId": 14,
        "brandName": "FUR PET",
        "active_ingredient": ["Each tablet contains: Lecithin 380mg", "Brewer's yeast 50mg", "MSM 50mg", "Zinc 5mg", "Cysteine 3mg", "Vitamin B3 1.14mg", "Vitamin B2 1mg", "Vitamin B5 1mg", "Iodine 200mcg", "Biotin 150mcg", "Copper 20mcg", "Vitamin B6 0.1mg", "Carotenoids 0.01mg", "Vitamin A 500I.U.", "Vitamin E 10I.U."],
        "theropetic_segment": ['NUTRICEUTICAL'],
        "pack_details": ["10 Tablets"]
    },
    {
        "brandId": 15,
        "brandName": "LACTO PET",
        "active_ingredient": ["Each tablet contains: Shatavari 1000 mg", "Fenugreek (Methi) 20 mg", "Garden Cress (Aliv) 10 mg"],
        "theropetic_segment": ['NUTRICEUTICAL'],
        "pack_details": ["10 Tablets"]
    },
    {
        "brandId": 16,
        "brandName": "LIVER PASTE",
        "active_ingredient": ["The Amino Acid profile for liver extract paste is as follows : Each 100gm contains: Alanine 2.35%","Arginine 2.36%","Aspartic Acid 3.49%","Cystine 0.58%","Glutamic Acid 6.52%","Glycine 3.18%","Histidine 1.05%","Isoleucine 1.48%","Leucine 3.23%","Lysine 3.11%","Methionine 0.84%","Phenylalanine 1.75%","Proline 1.97%","Serine 0.51%","Threonine 0.97%","Tryptophan 0.55%","Tyrosine 1.59%","Valine 2.29%","Vitamin B12 7 mcg/g","Protein 50% " ,"Does not contain pork or its derivatives or lard.","Nutrient Value: Protein: 3.5136 KCAL + Carbohydrate: Nil + Fat: Nil","Other Ingredient: P.E.G.4000 + Lakpol-80 + ISO Propyl Alcohol Sodium Benzoate"],
        "theropetic_segment": ['NUTRICEUTICAL'],
        "pack_details": ["100 gm"]
    },
    {
        "brandId": 17,
        "brandName": "CANOMEGA PET SYRUP",
        "active_ingredient": ["Each 10gm contains: Omega 9 Omega 6 Omega 3 7800mg"],
        "theropetic_segment": ['NUTRICEUTICAL'],
        "pack_details": ["200 ml"]
    },
    {
        "brandId": 18,
        "brandName": "TUNA THIK SOUP",
        "active_ingredient": ["Each 100 gm contains : Energy 55 kcal", "Fat 2.6 gm","Saturated Fat 18 mg","Carbohydrates 2.8 gm","Protein 9 gm","Sodium 30 mg","Ingredients: Refined sunflower oil, Tuna Fish Fresh Vegetables, Rice, Salt."],
        "theropetic_segment": ['NUTRICEUTICAL'],
        "pack_details": ["100gm x 5 pouch"]
    },
    {
        "brandId": 19,
        "brandName": "CEGALBU POWDER",
        "active_ingredient": ["Amount Per 100 gm : Calories 402","Total Fat 0 gm","Saturated Fat 0 gm","Trans Fat 0  gm","Cholesterol 0 gm","Sodium 1250 mg","Total Carbohydrate 4.5 gm ","Dietary Fiber 0 gm","Sugar 0 gm","Protein 78 gm","Vitamin B12 0.6 mcg","Calcium 89 mg","Vitamin C 0 mg","Iron 0.3 mg"],
        "theropetic_segment": ['ALBUMIN'],
        "pack_details": ["100 gm"]
    },
    {
        "brandId": 20,
        "brandName": "CARINENUT",
        "active_ingredient": ["Composition : Ketoconazole 2% w/v", "Shampoo base q.s."],
        "theropetic_segment": ['SHAMPOO'],
        "pack_details": ["200 ml"]
    },

]