<div>
    <div>
        <img class="d-block w-100" src="assets/aboutus.png">
    </div>
    <br>
    <div class="about-section">
        <h1 style="color:darkslategray;font-weight: 700;">About Us</h1>
        <br>
        <p>
            EXCELLAR HEALTHCARE, as the name suggests is a marketing company, "PAR EXCELLENCE" engaged mainly in Veterinary Pharmaceuticals and Feed Supplements
            spread across pan india from J & K in the north Karnataka in south and Maharashtra in the west to North Eastern states.  
        </p>
        <p>
            Built on dedicated experience of more than 25 years, the company has been soaring newer heights and setting up new benchmarks in quality for Veterinary
            Aqua Culture and Canine Pharmaceuticals and Feed Supplements. The company has recently ventured into Human healthcare segment.
        </p>
        <p>
            More than 200 marketing professionals, with sound knowledge of the trade, are in the service to farmers to understand and address the challenges 
            faced by them in a dedicated manner.
        </p>
        <p>
            Over the years the company’s dedication to support the veterinarians and live stock owners who raise and care for the farm and 
            companion animals, has earned the trust and confidence of a richly varied and strong client base. It is of utmost importance to us that 
            this relationship is clear, client friendly and result oriented and WE ARE COMMITTED to it. 
        </p>
    </div>
</div>