import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AboutComponentComponent } from './features/dashboard/about-component/about-component.component';
import { ContactUsComponent } from './features/dashboard/contact-us/contact-us.component';
import { HomeComponent } from './features/dashboard/home/home.component';
import { ProductComponentComponent } from './features/dashboard/product-component/product-component.component';


const routes: Routes = [
  {

    path: '',
    // loadChildren: () => import('../app/features/dashboard/home/home.component').then(m => m.DashbaordModule),
    pathMatch: 'full',
    component: HomeComponent
  },
  {

    path: 'home',
    // loadChildren: () => import('../app/features/dashboard/home/home.component').then(m => m.DashbaordModule),
    pathMatch: 'full',
    component: HomeComponent
  },
  {

    path: 'aboutus',
    // loadChildren: () => import('../app/features/dashboard/home/home.component').then(m => m.DashbaordModule),
    pathMatch: 'full',
    component: AboutComponentComponent
  },
  {

    path: 'products',
    // loadChildren: () => import('../app/features/dashboard/home/home.component').then(m => m.DashbaordModule),
    pathMatch: 'full',
    component: ProductComponentComponent
  },
   {

    path: 'products/:id',
    // loadChildren: () => import('../app/features/dashboard/home/home.component').then(m => m.DashbaordModule),
    pathMatch: 'full',
    component: ProductComponentComponent
  },
  {

    path: 'contactus',
    // loadChildren: () => import('../app/features/dashboard/home/home.component').then(m => m.DashbaordModule),
    pathMatch: 'full',
    component: ContactUsComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
