export const animalProductData = [
    {
        "brandId": 1,
        "brandName": "AMPIXEL LIQUID",
        "active_ingredient": ["Each 5 ml contains: Vitamin B1 7.0 mg", "Vitamin B2 2.5 mg", "Vitamin B6 1.0 mg", "Vitamin B12 12.5 mcg", "Biotin 25.0 mcg", "Calcium Pantothenate 2.5mg", "Niacinamide 75.0 mg", "Choline Chloride 10.0 mg", "D. L. Methionine 10.0 mg", "L. Lysine 20.0 mg"],
        "theropetic_segment": ['FEED SUPPLEMENT OF AMINO ACID & B-VITAMIN FOR LIVESTOCK & POULTRY'],
        "pack_details": ["200 ml/ 500 ML/ 1 LTR/ 5 LTR"]
    },
    {
        "brandId": 2,
        "brandName": "BUFFER",
        "active_ingredient": ["Each Sachet contains: Tri Sodium Citrate 15 Gms",],
        "theropetic_segment": ['Animal body pH gets changes due to several reason like temperature, pressure and various type of diseases',' Buffer as solution when given orally to an animal can keep pH constant in the animals body.',  ' Blood is the main carrier of all vital substance of body, blood can perform this function only when pH is maintained at the range of 7.2-7.4'],
        "pack_details": ["15 Gms"]
    },
    {
        "brandId": 3,
        "brandName": "CALPHOS D3 BOLUS",
        "active_ingredient": ["Each Bolus Contains : Calcium 1700 mg.", "Phosphorous 850 mg.", "Vitamin D3 8000 I.U.", "Vitamin B12 100 mcg.", "Asparagus racemosus 1 gm.", "Leptodenia reticulata 1.5 gm."],
        "theropetic_segment": ['Galactogogue bolus fortified with macro minerals and vitamins for increased productivity'],
        "pack_details": ["4's"]
    },
    {
        "brandId": 4,
        "brandName": "CALPHOFEROL GEL",
        "active_ingredient": ["Each 21g contains : Malt Extract 4.52g", "Calcium Gluconate 360 mg", "Ferric Ammonium Citrate 300mg(eqv. To 64.5mg of elemental Iron)", "Copper Sulphate 100mg", "Cobalt Chloride 1.5mg", "Cholecalciferol (Stabilized Vit D3) 3600mg", "Niacinamide 45mg", "Biotin 75mg", "Folic Acid 1.5mg", "Cyanocobalamine (Vit B 12) 15mcg", "Flavoured base q.s."],
        "theropetic_segment": ['PALATABLE HEALTH REJUVENATOR & HAEMATINIC'],
        "pack_details": ["450 gm/1 Kg/ 5 kg"]
    },
    {
        "brandId": 5,
        "brandName": "CALCINET Gel",
        "active_ingredient": ["Each 100gm CALCINET Gel Provides: Calcium 6600 mg", "Phosphorus 3400 mg", "Vitamin D3 32000 IU", "Vitamin B12 400 mcg", "Pueraria Mirifica ext 200 mg Appropriate overages of vitamins are added."],
        "theropetic_segment": ["GEL"],
        "pack_details": ["300 gm"]
    },
    {
        "brandId": 7,
        "brandName": "E. R. S.",
        "active_ingredient": ["Each 100 ml. contains (EXCELLENT REHYDRATION SOLUTION): Calcium Lactate 0.1200 g.", "Potassium Chloride 1.8625 g.", "Magnesium Sulphate 0.3000 g.", "Sodium Chloride 0.2923 g.", "Sodium Acid Phosphate 0.6240 g.", "Tri Sodium Citrate 4.2153 g.", "Dextrose Monohydrate 40.000 g."],
        "theropetic_segment": ['LIQUID'],
        "pack_details": ["200 ml/ 500 ml/ 1 ltr/ 5 ltr"]
    },
    {
        "brandId": 8,
        "brandName": "FLORABOOST BOLUS",
        "active_ingredient": ["Each Bolus provides: Live yeast culture 3 gm", "Live Lactobacillus Sporogenes 20 m CFU", "Amino acids 2 gm", "Liver extract 5 mg", "Excipients q.s."],
        "theropetic_segment": ['Benign factor to upset the Rumen', 'Antibiotic Antidiarrhoeal Anthelmintic NSAID', 'REJUVENATE THE RUMEN WITH FLORABOOST BOLUS', 'Live yeast culture & live Lactobacillus stimulate speedy growth of Rumen', 'Flora which results in improvement of RUMEN ECOLOGY'],
        "pack_details": ["4's Bolus each"]
    },
    {
        "brandId": 9,
        "brandName": "FLORABOOST - AD3",
        "active_ingredient": ["Each 100gm contains: Live Yeast Culture 5 mg", "Live Lactobacillus sporogenes", "culture 30 million C.F.U.", "Amino acids 2.5 gm", "Liver Extract 2.5 mg", "Alpha amylase 0.5 gm", "Vitamin A 10,00000 I.U.", "Vitamin D3 1,00000 I.U.", "Trace minerals as base", "Appropiate overages and Antioxidants added."],
        "theropetic_segment": ["POWDER"],
        "pack_details": ["50 gm, 100 gm, 500 gm"]
    },
    {
        "brandId": 9,
        "brandName": "FLORABOOST (WATER SOLUBLE)",
        "active_ingredient": ["Each 100gm contains: Live Yeast Culture 5 mg", "Live Lactobacillus  30 million", "sporogenes culture ", "Amino acids 2.5 gm", "Alpha Amylase 0.5 gm", "Vitamin A 10,000,00 I.U.", "Vitamin D3 1,000,00 I.U.", "Trace Minerals as base", "Liver Extract 2.5 gm"],
        "theropetic_segment": ["POWDER"],
        "pack_details": ["500 gms"]
    },
    {
        "brandId": 10,
        "brandName": "FEROLIV DS LIQUID",
        "active_ingredient": ["Each 30 ml contains: Calcium 600 mg.", "Ferric Chloride 160 mg.", "Vitamin B1 10 mg.", "Vitamin B2 10 mg.", "Nicotinamide 90 mg.", "Yeast Extract 80 mg.", "Choline Chloride 20 mg.", "Fresh Liver Exact 300 mg."],
        "theropetic_segment": ['Oral Preparation of Calcium Iron & Vitamin B-Complex with high potency liver extract for guaranteed result.'],
        "pack_details": ["1 Litre/ 5 Litre"]
    },
    {
        "brandId": 11,
        "brandName": "FEROLIV LIQUID",
        "active_ingredient": ["Each 30 ml contains: Calcium 300 mg.", "Ferric Chloride 80 mg.", "Vitamin B1 5 mg.", "Vitamin B2 5 mg.", "Nicotinamide 45 mg.", "Yeast Extract 40 mg.", "Choline Chloride 10 mg.", "Fresh Liver Exact 150 mg."],
        "theropetic_segment": ['A new direction in the management of Liver Disorder', 'Liquid Feed Supplement For Livestock & Poultry'],
        "pack_details": ["200 ml/ 500 ml/ 1 ltr/ 5 ltr"]
    },
    {
        "brandId": 12,
        "brandName": "FEROLIV - FA BOLUS",
        "active_ingredient": ["Each Bolus Contains: Ferric Chloride 80 mg", "Thiamine Hydrochloride(B1) 5 mg", "Riboflavin (B2) 5 mg", "Nicotinamide 45 mg", "Calcium 300 mg", "Yeast Extract 40 mg", "Choline Chloride 10 mg", "Fresh Liver Extract 150 mg"],
        "theropetic_segment": ['THE NEW CONCEPT IN THE MANAGEMENT OF LIVER DISORDER', 'FEROLIV FA Bolus is a fast acting bolus which when dropped intact in rumen liquor immediately dissolved with Fizz process resulting in Faster absorption.'],
        "pack_details": ["4's"]
    },
    {
        "brandId": 13,
        "brandName": "H.H.+",
        "active_ingredient": ["Each 10 ml Contains: Liver fraction 1.25 gm (derived from 31.25gm of fresh liver) ", "Yeast Extract 400 mg", "Nicotinic Acid 24 mg", "Vitamin E 300 mcg"],
        "theropetic_segment": ['LIQUID'],
        "pack_details": ["200 ml/ 500 ml/ 1 ltr/ 5 ltr"]
    },
    {
        "brandId": 13,
        "brandName": "H.K+ LIQUID",
        "active_ingredient": ["Each 10 ml Contains:  Liver fraction 1.25 gm (derived from 31.25gm of fresh liver)", "Yeast Extract 400 mg", "Nicotinic Acid 24 mg", "Vitamin E 300 mcg"],
        "theropetic_segment": ['LIVER TONIC FORTIFIED WITH YEAST & VITAMIN'],
        "pack_details": ["200 ml/ 500 ml/ 1 ltr/ 5 ltr"]
    },
    {
        "brandId": 14,
        "brandName": "K.C.+ BOLUS",
        "active_ingredient": ["Each K.C.+ Bolus Provides: Activated Charcoal 1200 iv 400 mg", "Fungal Diastase 240 mg", "Papain 300 mg", "Excipients q.s."],
        "theropetic_segment": ['A WELL BALANCE FORMULA OF ACTIVATED CHARCOAL FUNGAL DIASTASE AND PAPAINE'],
        "pack_details": ["20 Strips of 4 Bolus each"]
    },
    {
        "brandId": 15,
        "brandName": "LOPA -L",
        "active_ingredient": ["Each 1000 ml contains: Safed musli extract 20 gm",
            "Panax ginseng liquid extract 15 ml",
            "Pueraria mirifica extract 10 gm",
            "Purified honey 100 ml",
            "Niacinamide 15 gm",
            "Calcium Lactate 50 gm",
            "Olive oil 30 gm",
            "Glycerin 300 ml",
            "Purified water q.s."],
        "theropetic_segment": ['A anti stress formula of Calcium, Vitamins & Herbal preparation for higher milk production in dairy animals.'],
        "pack_details": ["1 litre"]
    },
    {
        "brandId": 15,
        "brandName": "LOPA -L",
        "active_ingredient": ["Each 200 ml contains: Calcium Lactate 10 gm",
            "Olive oil 6 gm",
            "Safed musli extract 4 gm",
            "Niacinamide 3 gm",
            "Pueraria mirifica extract 2 gm",
            "Glycerin 60 ml",
            "Purified honey 20 ml",
            "Panax ginseng liquid extract 3 ml",
            "Purified water q.s."],
        "theropetic_segment": ['LIQUID'],
        "pack_details": ["200 ml"]
    },
    {
        "brandId": 15,
        "brandName": "LOPA -L",
        "active_ingredient": ["Each 1000 ml contains: Calcium Lactate 50 gm",
            "Olive oil 30 gm",
            "Safed musli extract 20 gm",
            "Niacinamide 15 gm",
            "Pueraria mirifica extract 10 gm",
            "Glycerin 300 ml",
            "Purified honey 100 ml",
            "Panax ginseng liquid extract 15 ml",
            "Purified water q.s."],
        "theropetic_segment": ['LIQUID'],
        "pack_details": ["500 ml"]
    },
    {
        "brandId": 16,
        "brandName": "MINEREX BOLUS",
        "active_ingredient": ["Each Bolus Contains: Vitamin A 1000 I.U",
            "Vitamin D3 500 I.U",
            "Vitamin E 20 mg",
            "Cobalt sulphate 0.056 gm",
            "Copper sulphate 0.700 gm",
            "Potassium Iodine 0.140 gm",
            "Iron Sulphate 1.400 gm",
            "Manganese Sulphate 0.560 gm",
            "Seleniu Dioxide 0.004 gm",
            "Zinc Sulphate 0.280 gm"],
        "theropetic_segment": ['LONG - ACTING VITAMIN & MINERALS BOLUS Combination of Fertility Vitamins & Trace Mineral', 'The best way to get minerals Supplement from outside Viamins & Trace Minerals'],
        "pack_details": ["20 Strips of 4 Bolus each"]
    },
    {
        "brandId": 17,
        "brandName": "MINEREX FORTE - (CHELATED)",
        "active_ingredient": ["Each 1kg pack Contains: Vitamin A 20,00,000 I.U.",
            "Vitamin D3 4,00,000 I.U.",
            "Vitamin E 300 I.U.",
            "Vitamin B12 2.4 mg",
            "Vitamin K3 0.4 gm",
            "Calcium D Pantothenate 1 gm",
            "Choline Chloride 60 gm",
            "Calcium 300 gm",
            "Manganese 11 gm",
            "Iron 3 gm",
            "Copper 0.8 gm",
            "Cobalt 180 mg",
            "Selenium 40 mg",
            "Nicotinamide 4 gm",
            "Vitamin B2 0.8 gm",
            "Zinc 2128 mg"],
        "theropetic_segment": ["POWDER"],
        "pack_details": ["1 kg"]
    },
    {
        "brandId": 18,
        "brandName": "MINEREX FORTE - (CHELATED)",
        "active_ingredient": ["Each 1 kg pack Contains: Vitamin A 50,00,000 I.U.",
            "Vitamin D3 10,00,000 I.U.",
            "Vitamin E 750 I.U.",
            "Vitamin B12 6 mg",
            "Vitamin K3 1 gm",
            "Calcium D Pantothenate 2.5 gm",
            "Choline Chloride 150 gm",
            "Calcium 750 gm",
            "Manganese 27.5 gm",
            "Iron 7.5 gm",
            "Copper 2 gm",
            "Cobalt 450 mg",
            "Selenium 100 mg",
            "Nicotinamide 10 gm",
            "Vitamin B2 2 gm",
            "Zinc 5320 mg"],
        "theropetic_segment": ["POWDER"],
        "pack_details": ["5 kg/ 2.5 Kg/ 10 Kg"]

    },
    {
        "brandId": 18,
        "brandName": "MINEREX FORTE - (CHELATED)",
        "active_ingredient": ["Each 250 gm pack Contains: Vitamin A 5,00,000 I.U.",
            "Vitamin D3 1,00,000 I.U.",
            "Vitamin E 75 I.U.",
            "Vitamin B12 0.6 mcg",
            "Vitamin K3 0.1 gm",
            "Calcium Pantothenate 0.25 gm",
            "Choline Chloride 15 gm",
            "Calcium 75 gm",
            "Manganese 2.75 gm",
            "Iron 0.75 gm",
            "Copper 0.2 gm",
            "Cobalt 45 mg",
            "Selenium 1 mg",
            "Nicotinamide 10 gm",
            "Vitamin B2 0.2 gm",
            "Zinc 532 mg"],
        "theropetic_segment": ["POWDER"],
        "pack_details": ["250 gm"]

    },
    {
        "brandId": 19,
        "brandName": "MINEREX GOLD",
        "active_ingredient": ["Each uncoated bolus : Copper 120 mg",
            "Zinc 240 mg",
            "Manganese 240 mg",
            "Iodine 6.5 mg",
            "Vitamin A 50000 I.U.",
            "Vitamin D3 50000 I.U.",
            "Vitamin E 100 I.U."],
        "theropetic_segment": ['MAGIC FORMULA OF VITAL VITAMINS AND ESSENTIAL MINERALS'],
        "pack_details": ["4's"]
    },
    {
        "brandId": 20,
        "brandName": "MINEREX PLUS",
        "active_ingredient": ["Each 1.2 kg has nutritional value of : Vitamin A 700000 I.U.",
            "Vitamin D3 70000 I.U.", "Calcium 255 gm.", "Phosphorus 127.5 gm.",
            "Magnesium 6.0 gm.", "Manganese 1.5 gm.", "Iron 1.5 gm.", "Iodine 325 mg.",
            "Copper 4.2 gm", "Zinc 9.6 gm.", "Cobalt 150 mg.", "Sulphur 7.2 gm.", "Potassium 100 mg.", "Sodium 6 mg.",
            "Selenium 10 mg.", "Vitamin E 250 mg.", "Niacin 1000 mg.", "Chromium 78 mg."],
        "theropetic_segment": ['A novel formulation of MHA chelated minerals and vitamins for our productive animals'],
        "pack_details": ["1.2 kg"]

    },
    {
        "brandId": 20,
        "brandName": "MINEREX PLUS",
        "active_ingredient": ["Each 6 kg has nutritional value of : Vitamin A 3500000 I.U.",
            "Vitamin D3 3500000 I.U.",
            "Calcium 1275 gm.",
            "Phosphorus 637.5 gm.",
            "Magnesium 30 gm.",
            "Manganese 7.5 gm.",
            "Iron 7.5 gm.",
            "Iodine 1625 mg.",
            "Copper 21 gm",
            "Zinc 48 gm.",
            "Cobalt 750 mg.",
            "Sulphur 36 gm.",
            "Potassium 500 mg.",
            "Sodium 30 mg.",
            "Selenium 50 mg.",
            "Vitamin E 1250 mg.",
            "Niacin 5000 mg.",
            "Chromium 390 mg."],
        "theropetic_segment": ['POWDER'],
        "pack_details": ["6 kg"]
    },
    {
        "brandId": 20,
        "brandName": "MINEREX PLUS",
        "active_ingredient": ["Each 250 gm has nutritional value of : Vitamin A 145833 I.U.",
            "Vitamin D3 145833 I.U.",
            "Calcium 53.125 gm.",
            "Phosphorus 26.563 gm.",
            "Magnesium 1.25 gm.",
            "Manganese 0.313 gm.",
            "Iron 0.313 gm.",
            "Iodine 67.708 mg.",
            "Copper 0.875 gm",
            "Zinc 2 gm.",
            "Cobalt 31.250 mg.",
            "Sulphur 1.5 gm.",
            "Potassium 20.833 mg.",
            "Sodium 1.25 mg.",
            "Selenium 2.083 mg.",
            "Vitamin E 52.083 mg.",
            "Niacin 208.333 mg.",
            "Chromium 16.25 mg."],
        "theropetic_segment": ['POWDER'],
        "pack_details": ["250 gm"]

    },
    {
        "brandId": 21,
        "brandName": "STRESSVIT",
        "active_ingredient": ["Each ml comtains: Vitamin A 12000 I.U.", "Vitamin D3 6000 I.U.", "Vitamin E 48 mg.", "Vitamin B12 20 mcg."],
        "theropetic_segment": ['Liquid feed supplement of vitamins for cattle & poultry'],
        "pack_details": ["30 ml/60 ml/500 ml"]
    },
    {
        "brandId": 22,
        "brandName": "STRESSVIT GOLD",
        "active_ingredient": ["Each 10 ml. Contains: Vitamin A 120000 I.U.", "Vitamin D3 60,000 I.U.", "Vitamin E 30 mg.", "Vitamin H (Protin) 1.5 mg.", "Vitamin C 15 mg.", "Zinc 180 mg.", "Cobalt 1 mg.", "Selenrum 1 mg."],
        "theropetic_segment": ['Liquid feed supplement of vitamins for cattle & poultry'],
        "pack_details": ["120ml/500ml"]
    },

    {
        "brandId": 24,
        "brandName": "SUPERCARE-365 BOLUS",
        "active_ingredient": ["Each 100 ml contains: Elemental Calcium 2000 mg (Derived from Oyster shell)",
            "Ferrous Sulphate 600 mg (in sustained release form)",
            "Zinc Sulphate 100 mg (in sustained release form)",
            "Magnesium Oxide 20 mg",
            "Thiamine Hydrochloride 8 mg ",
            "Vitamin B2 8 mg ",
            "Vitamin B6 6 mg",
            "Vitamin B12 5 mcg",
            "Folic Acid 2 mg ",
            "Vitamin D3 1000 I.U.",
            "Vitamin E Acetate 40 mg",
            "Di Calcium Phosphate as base.Q.S."],
        "theropetic_segment": ['PROPER CARE DURING DELICATE MOMENTS BY MACRO & MICRO MINERALS WITH VITAMINS'],
        "pack_details": ["4's"]
    },
    {
        "brandId": 25,
        "brandName": "TANT POWDER",
        "active_ingredient": ["Each 15g contains: Amylase 1g ",
            "Cellulose 3g",
            "Invertase 1g",
            "Phytase 2g",
            "Lipase 1g",
            "Bypass Fat 1g",
            "Bypass Protein 1g",
            "Amino Nitrogen 0.950g",
            "Vit E 0.044g",
            "Protease 2g",
            "Selenium 0.006g",
            "Saccaromyces cerevisae 2g"],
        "theropetic_segment": ["TRIPLE ACTION : Enzyme, Bypass Nutrients Probiotics & Antioxidants that Triggers A Unique Combination of digestive enzymes, protein, bypass fat, probiotics and antioxidants together as a veterinary formulation"],
        "pack_details": ["15 gm"]
    },
    {
        "brandId": 26,
        "brandName": "XL - ON BOLUS",
        "active_ingredient": ["Each Bolus Contains: Ferrous Fumerate 1500 mg",
            "Thiamine Mononitrate 8.0 mg",
            "Vitamin B2 8.0 mg",
            "Vitamin B6 6.0 mg",
            "Vitamin B12 75 mcg",
            "Folic Acid 7.5 mg",
            "Excipients q.s."],
        "theropetic_segment": ['Cure Anemia, Debility and Malaise'],
        "pack_details": ["4's"]
    },
    {
        "brandId": 27,
        "brandName": "XLR-AD3 POWDER",
        "active_ingredient": ["Each gram contains: Vitamin A 50000 I.U.", "Vitamin D3 5000 I.U."],
        "theropetic_segment": ['Hi potency Vitamin A & D3'],
        "pack_details": ["20 gms/ 1 Kg"]
    }

]