<section>
  <!-- <div class="container-fluid" style="padding-top: 20px;"> -->
    <div>
    <div id="carouselHero" class="carousel slide" data-ride="carousel" data-interval="10000">
      <div class="carousel-inner">
        <div class="carousel-item active">
          <div class="view">
            <video class="video-item" autoplay loop muted playsinline width="100%" class="d-block w-100" oncanplay="this.play()" onloadedmetadata="this.muted = true">
              <!-- <source src="https://player.vimeo.com/external/440308113.sd.mp4?s=cc07d3c3a92790ae6de066940915cd915118de01&profile_id=139&oauth2_token_id=57447761" type="video/mp4"> -->
              <!-- <source src="assets/cow_man.mp4" type="video/mp4"> -->
              <source src="assets/video2.mp4" type="video/mp4">

            </video>
          </div>
          <!-- <div class="embed-responsive embed-responsive-16by9">
            <iframe class="embed-responsive-item" src="https://player.vimeo.com/external/440308113.sd.mp4?s=cc07d3c3a92790ae6de066940915cd915118de01&profile_id=139&oauth2_token_id=57447761" allowfullscreen></iframe>
          </div> -->
        </div>
        <div class="carousel-item ">
          <img src="assets/image1.jpg" class="d-block w-100" alt="..." >
        </div>
       
        <div class="carousel-item ">
          <img src="assets/image2.jpg" class="d-block w-100" alt="..." >
        </div>
        <div class="carousel-item ">
          <img src="assets/image3.jpg" class="d-block w-100" alt="..." >
        </div>
        <div class="carousel-item ">
          <img src="assets/image4.jpg" class="d-block w-100" alt="..." >
        </div>
      </div>
      <a class="carousel-control-prev" href="#carouselHero" role="button" data-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="sr-only">Previous</span>
      </a>
      <a class="carousel-control-next" href="#carouselHero" role="button" data-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="sr-only">Next</span>
      </a>
    </div>
  </div>
</section>
<!-- new carousal end -->
<br>

<div class="container-fluid">
  <h1 class="productclass"> OUR PRODUCTS</h1>
  <div class="row">
    <div class="col-sm" style="padding-bottom: 20px;">
      <div class="card" [routerLink]="['/products/1']" style="text-decoration:none;cursor: pointer;">
        <img src="assets/cow8.jpg" class="card-img-top" alt="..." style="cursor: pointer;">
        <div class="card-body">
          <h5 class="card-title"><a style="text-decoration:none;cursor: pointer;">VETERINARY
              PHARMACEUTICALS</a> </h5>
        </div>
      </div>
    </div>
    <br>
    <div class="col-sm" style="padding-bottom: 20px;">
      <div class="card" [routerLink]="['/products/2']" style="text-decoration:none;cursor: pointer;">
        <img src="assets/cow9.jpg" class="card-img-top" alt="...">
        <div class="card-body">
          <h5 class="card-title"><a style="text-decoration:none;cursor: pointer;">ANIMAL FEED SUPPLEMENTS</a></h5>
        </div>
      </div>
    </div>
    <div class="col-sm" style="padding-bottom: 20px;">
      <div class="card" [routerLink]="['/products/3']" style="text-decoration:none;cursor: pointer;"> 
        <img src="assets/Katlafish.jpg" class="card-img-top" alt="...">
        <div class="card-body">
          <h5 class="card-title"><a style="text-decoration:none;cursor: pointer;">AQUACULTURE</a></h5>
          <br>
        </div>
      </div>
    </div>
    <div class="col-sm" style="padding-bottom: 20px;">
      <div class="card" style="text-decoration:none;cursor: pointer;" [routerLink]="['/products/4']">
        <img src="assets/dog with glasses2.jpg" class="card-img-top" alt="...">
        <div class="card-body">
          <h5 class="card-title"><a style="text-decoration:none;cursor: pointer;">CANINE</a></h5>
          <br>
        </div>
      </div>
    </div>
    <!-- <div class="col-sm" style="padding-bottom: 20px;">
      <div class="card" [routerLink]="['/products/5']" style="text-decoration:none;cursor: pointer;">
        <img src="assets/human1.jpg" class="card-img-top" alt="...">
        <div class="card-body">
          <h5 class="card-title"><a style="text-decoration:none;cursor: pointer;">HUMAN</a></h5>
          <br>
        </div>
      </div>
    </div> -->
  </div>
</div>