import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { HttpService } from 'src/app/service/http.service';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css']
})
export class ContactUsComponent implements OnInit {

  form: FormGroup;
  name: FormControl = new FormControl("", [Validators.required]);
  email: FormControl = new FormControl("", [Validators.required, Validators.email]);
  message: FormControl = new FormControl("", [Validators.required, Validators.maxLength(256)]);
  issubmitted: boolean = false; //show and hide the success message
  isLoading: boolean = false; // disable the submit button if we're loading
  responseMessage: string; //the response msg to show to the user
  msgcolor : string;

  constructor(private formBuilder: FormBuilder, private api: HttpService) {
    this.form = this.formBuilder.group({
      name: this.name,
      email: this.email,
      message: this.message
    });
  }

  ngOnInit(): void {
  }

  onSubmit() {    
    if (this.form.get('email').value != "" && this.form.get('name').value != "" && this.form.get('message').value !="") {
      // console.log(this.form.get('email').value +"/" +this.form.get('').value +"/" +this.form.get('message').value);
      const params = this.form.get('email').value + "/" + this.form.get('name').value + "/" + this.form.get('message').value;
      this.api.get(params).subscribe((res: any) => {
        console.log("resposne is-----", res);
        if(res.status == true){
          this.issubmitted = true;
          this.responseMessage = "Thank you for contacting us. We will get back to you soon.";
          this.msgcolor = "green";
        }else{
          this.issubmitted = true;
          this.responseMessage = "Something went wrong";
          this.msgcolor = "red";
        }
      
      });
    } else { 
      console.log("in elsee");
      this.issubmitted = true;
      this.responseMessage = "All Fields required";
      this.msgcolor = "red";
    }
  }
}
