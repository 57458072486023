export const aquaProductData = [
    {
        "brandId": 1,
        "brandName": "AMMO KILL",
        "active_ingredient": ["Bacillus Subtilis", " Bacillus Licheniformis"," Yucca schidigera extract"],
        "theropetic_segment": ['AMMONIA KILLER Keep bottom of pond healthy'],
        "pack_details": ["500 gm & 250 gm"]
    },
    {
        "brandId": 2,
        "brandName": "AQUA MAGIC POWDER",
        "active_ingredient": ["Natural Hydrated Sodium Calcium Aluminium Silicate (HSCAS)"],
        "theropetic_segment": ['NATURAL GEO-LITE',' Extinguishes harmful gas and Increase oxygen level in the pond.',' Mixture of Natural Zeolites for use in Aquatic Feed'],
        "pack_details": ["5 kg & 25 kg"]
    },
    {
        "brandId": 3,
        "brandName": "AQUA MAGIC GRANULES",
        "active_ingredient": ["Natural Hydrated Sodium Calcium Aluminium Silicate (HSCAS)"],
        "theropetic_segment": ['TOXIN BINDER'],
        "pack_details": ["10 kg & 20 kg"]
    },
    {
        "brandId": 4,
        "brandName": "AQUA YEAST POWDER",
        "active_ingredient": ["Yeast Cultures,  Enzymes & Metabolites"],
        "theropetic_segment": ['Develops food canal in the fish'],
        "pack_details": ["1 kg"]
    },
    {
        "brandId": 5,
        "brandName": "AQUA YEAST GRANULES",
        "active_ingredient": ["Yeast Cultures"," Enzymes"," Metabolites"],
        "theropetic_segment": ['Develops food canal in the fish'],
        "pack_details": ["250 gm & 1 kg & 25 kg"]
    },
    {
        "brandId": 6,
        "brandName": "BFG",
        "active_ingredient": ["Aquaculture grade Formation", "Benzylkonium Chloride and suitable stabilizer in appropriate proportion."],
        "theropetic_segment": ["Water sanitizer"],
        "pack_details": ["5 Litre"]
    },
    {
        "brandId": 7,
        "brandName": "CEF-XLR POWDER",
        "active_ingredient": ["Each gm contains CEPHALEXIN IP - 1500 mg."],
        "theropetic_segment": ['A Treatment for Disease Prevention in Fish Improves fish health'],
        "pack_details": ["20 gm. & 200 gm."]
    },
    {
        "brandId": 8,
        "brandName": "ENDO-KILL PLUS",
        "active_ingredient": ["Deltamethrin B.P. (VET) 28 mg","Solvent & Emulsifiers q.s."],
        "theropetic_segment": ['Killers of Ecto-parasite'],
        "pack_details": ["50ml ,250 ml & 1 ltr."]
    },
    {
        "brandId": 9,
        "brandName": "ENDECTIN POWDER",
        "active_ingredient": ["IVERMECTIN IP (VET.) 1% w/w"],
        "theropetic_segment": ['Effective Eliminator of External as well as Internal Parasitic Organism in the Fish Example - Urgulas, Larnia, Protozoya, Ergacilus, Gill fluke.','Develops longevity in fish'],
        "pack_details": ["100 gm"]
    },
    {
        "brandId": 10,
        "brandName": "EXCELLENT BOTTOM",
        "active_ingredient": ["Each 1 kg. contains: B. Subtillis 0.025 gm.", "B. Licheniformis 0.005 gm.","B. polymixa + B. megaterium 0.015 gm.","Nitrosomonas 0.020 gm.","Pseudomonas 0.020 gm.","q.s. 0.915 gm."],
        "theropetic_segment": ['MOST HELPFUL BACTERIA FOR SOIL FERTILITY'],
        "pack_details": ["500 gm & 1 kg."]
    },
    {
        "brandId": 11,
        "brandName": "EXIDINE",
        "active_ingredient": ["Alkyl aryl polyoxyethylene iodine complex along with emulsifying agent"],
        "theropetic_segment": ['BEST IODOPHOR FOR AQUACULTURE'],
        "pack_details": ["500 ml & 1ltr."]
    },
    {
        "brandId": 12,
        "brandName": "EXORENA - 4s",
        "active_ingredient": ["4th Generation Quaternary Ammonium Compound Quaternary","Ammonium Compound 7.5% w/v Inert","Ingredients q.s."],
        "theropetic_segment": ['Speedy Purification machine for Pond Water Purified Water and Assured Profit Excellent Water Sanitizer'],
        "pack_details": ["1 litre & 5 ltr."]
    },
    {
        "brandId": 13,
        "brandName": "EXORENA - 4s LIQUID - DS",
        "active_ingredient": ["Quaternary", "Ammonium Compound 15 % w/v Inert","Ingredients q.s."],
        "theropetic_segment": ['Excellent Water Sanitizer'],
        "pack_details": ["1 litre & 5 ltr."]
    },
    {
        "brandId": 14,
        "brandName": "EXO-LIV LIQUID",
        "active_ingredient": ["Each 30 ml contains: Ferric Chloride 80mg ", "Thiamine Hydrochloride (B1) 5mg","Riboflavin (B2) 5mg","Nicotinamide 45mg","Calcium 500mg","Yeast Extract 100mg","Choline Chloride 20mg","Fresh Liver Extract 300mg"],
        "theropetic_segment": ['Liquid Feed Supplement For Aquaculture use'],
        "pack_details": ["1 litre & 5 ltr."]
    },
    {
        "brandId": 15,
        "brandName": "EXTRABIND LIQUID",
        "active_ingredient": ["Each 20 gms contains : Protein 200 mg", "Cholesterol 50 mg","Calcium 20 mg","Vitamin D3 1600 I.U.","Carotenoid 100 mcg"],
        "theropetic_segment": ['PERFECT PROTEIN BINDING GEL WITH GROWTH PROMTER'],
        "pack_details": ["5 ltr"]
    },
    {
        "brandId": 16,
        "brandName": "H.K. PLUS",
        "active_ingredient": ["Each 4 gm Teaspoonful Contains: Oxytetracyclin Hydrochloride I.P 224 mg Excipients q.s"],
        "theropetic_segment": ['Water Soluble Powder','Oxytetracyclin HCL Soluble Powder IP 5.6% w/w (Vet)'],
        "pack_details": ["250 gm"]
    },
    {
        "brandId": 17,
        "brandName": "MINEREX FORTE",
        "active_ingredient": ["Nutritional Value per kg. contains : Vitamin A  7,00,000 I.U.","Vitamin D3  70,000 I.U.","Vitamin E  250 mg.","Nicotinamide 1000 mg.","Cobalt 150 mg.","Copper 1200 mg.","Iodine 325 mg.","Iron 1500 mg.","Magnesium 6000 mg.","Manganese 1500 mg.","Potassium 100 mg.","Selenium 10 mg.","Sodium 5.9 mg.","Sulphur 0.72%","Zinc 9600 mg.","Calcium 25.5% ","Phosphorus 12.75%"],
        "theropetic_segment": ['Vitamins & Minerals Supplement for fish Feeding'],
        "pack_details": ["1 kg,5 kg & 20 kg."]
    },
    {
        "brandId": 18,
        "brandName": "PLANKTOMIN",
        "active_ingredient": ["Powder form, each kg contains: Magnesium 6000 mg","Calcium 80000 mg","Phosphorus 40000 mg","Manganese 1500 mg","Potassium 1500 mg","Cobalt 150 mg","Copper 1200 mg","Iodine 325 mg","Iron 1500 mg","Selenium 10 mg","Sodium 585 mg","Zinc 9600 mg"],
        "theropetic_segment": ["Minerals"],
        "pack_details": ["10 Kg"]
    },
    {
        "brandId": 19,
        "brandName": "POND BIOTIC",
        "active_ingredient": ["Each 1 kg. contains: Live yeast culture 0.025 gm.","Bac. Coagulans 0.005 gm.","B. subtilis 0.007 gm.","MOS 0.020 gm.","Vitamin 'C' 0.020 gm.","Sea weed 0.005 gm.","FOS 0.005 gm.","Sod. Benzo. 0.006 gm.","q.s. 0.862 gm."],
        "theropetic_segment": ['PRO-BIOTIC INGREDIENT FOR WATER An useful developed pro biotic for all kinds of Aqua Culture management'],
        "pack_details": ["250 gm & 1 kg."]
    },  
    {
        "brandId": 20,
        "brandName": "PRONZI",
        "active_ingredient": ["A unique combination of Probiotics and enzymes"],
        "theropetic_segment": ['Super Quality of essential Probiotic added with vital enzymes'],
        "pack_details": ["1 kg"]
    },
      {
        "brandId": 21,
        "brandName": "SUPER PRONZI POWDER",
        "active_ingredient": ["B. Subtilis Phytase","B. Licheniformis Protease","B. Polymixa Sacharomyces Cerevisiae","B. Magaterium Lactobacillus","Nitrosomonas Activated Charcoal 1200iv","Pseudomonas Manganese","Bac. Coagulans Potassium","MOS Zinc","Vitamin C Sulphur","Sea weed Cobalt","Amylase Copper","Cellulase Iron Chromium"],
        "theropetic_segment": ['Useful for all types of Aquaculture'],
        "pack_details": ["500 gm & 1 kg"]
    },
    {
        "brandId": 23,
        "brandName": "RICHOXY GRANULES",
        "active_ingredient": ["Consortia of Dissolved oxygen releasers"],
        "theropetic_segment": ['Long Acting with high label of Oxygen Releasing Tablets in fishery Pond Produces High level Oxygen in the water'],
        "pack_details": ["1 kg."]
    },
    {
        "brandId": 24,
        "brandName": "RICHOXY TABLET",
        "active_ingredient": ["Consortia of Dissolved oxygen releasers"],
        "theropetic_segment": ['Long Acting with high label of Oxygen Releasing Tablets in fishery Pond Produces High level Oxygen in the water'],
        "pack_details": ["1 kg."]
    },
    {
        "brandId": 25,
        "brandName": "XL BKC 80",
        "active_ingredient": ["Benzalkonium Chloride 80% Solution."],
        "theropetic_segment": ['FOR AQUACULTURE HIGHLY POTENTIAL & HIGHLY CONCENTRAED BKC'],
        "pack_details": ["1 Ltr. & 5 ltr."]
    },
    {
        "brandId": 26,
        "brandName": "VMIN-C",
        "active_ingredient": ["Each gm contains: Vitamin C 500 mg","Stabilizers, Ani-oxidant And water soluble base"],
        "theropetic_segment": ["VITAMIN"],
        "pack_details": ["500 gms"]
    }
]