<div>
  <video class="video-item" autoplay loop muted width="100%"  class="d-block w-100">
    <source src="assets/product_video.mp4" type="video/mp4">
  </video>
</div>
<br><br>
<div style="padding-left: 30px;" class="container-fluid">
  <hr class="hrclass">

  <h4 style="margin-bottom: 20px;color:rgb(101, 172, 172);font-weight: 700;"> OUR PRODUCTS</h4>

  <form [formGroup]="form" (ngSubmit)="submit()">
    <div class="row" style="padding-left: 15px;">
      <div class="form-group" style="padding-right: 20px;">
        <select formControlName="products" class="form-control" (change)="onSelectProduct($event.target.value)">
          <option disabled>Select Products</option>
          <option>Choose Products</option>
          <option *ngFor="let products of productlist">{{products}}</option>
        </select>
      </div>
      <div class="form-group" style="padding-right: 20px;" *ngIf="showBrandList">
        <select formControlName="brands" class="form-control">
          <option disabled>Select Brand</option>
          <option>Choose Brand</option>
          <option *ngFor="let brands of brandslist">{{brands}}</option>
        </select>
      </div>
      <button class="searchbtn" type="submit" style="height: 40px;">Search</button>
    </div>

  </form>
</div>

<div class="mat-app-background basic-container mat-elevation-z8 small-table"> 
  <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
    <!-- brandName Column -->
    <ng-container matColumnDef="brandName">
      <th mat-header-cell *matHeaderCellDef style="background-color: gainsboro; color : black;padding-left: 15px;">
        Brand Name </th>
      <td mat-cell *matCellDef="let element" style="padding: 15px;font-size: 14px;font-weight: 800;color: red;"> {{element.brandName}} </td>
    </ng-container>

    <!-- active_ingredient Column -->
    <ng-container matColumnDef="active_ingredient">
      <th mat-header-cell *matHeaderCellDef style="background-color: gainsboro; color : black;padding-left: 15px;">
        Active Ingredient(s) </th>
      <td mat-cell *matCellDef="let element" style="padding: 15px;font-size: 14px;"> {{element.active_ingredient}}</td>
    </ng-container>

    <!-- theropetic_segment Column -->
    <ng-container matColumnDef="theropetic_segment">
      <th mat-header-cell *matHeaderCellDef style="background-color: gainsboro; color : black;padding-left: 15px;">
        Therapeutic Segment(s) </th>
      <td mat-cell *matCellDef="let element" style="padding: 15px;font-size: 14px;"> {{element.theropetic_segment}} </td>
    </ng-container>

    <!-- pack_details Column -->
    <ng-container matColumnDef="pack_details">
      <th mat-header-cell *matHeaderCellDef style="background-color: gainsboro; color : black;padding-left: 15px;"> Pack
        Details </th>
      <td mat-cell *matCellDef="let element" style="padding: 15px;font-size: 14px;color: blue;font-weight: 700;"><span style="font-weight: 700;color: black;">Pack : </span>{{element.pack_details}} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
<br> 
<!-- <mat-paginator #paginator [pageSize]="10" [showFirstLastButtons]="false" [length]="50">
</mat-paginator> -->
</div>