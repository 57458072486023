<footer class="page-footer center-on-small-only">
  <div class="container top-footer" >
      <div class="row">

          <div class="col-md-5 pl-4" >
            <h3 style="color: darkslategray;">Excellar Healthcare Pvt. Ltd.</h3>
            <P style="color: black;">202, Silverline, Opp. Holy Cross High School,
             Old Mumbai-Pune Road, Thane (West) - 400601,
             Maharashtra </P>
          </div>

          <div class="col-md-3" style="padding-bottom: 20px;">
            <h3 style="color: darkslategray;">Contact Us</h3>
            <i class='fas fa-phone-alt'><a href="tel:+(022) 25474142">  +(022) 25474142 </a></i>
            <br>

            <i class="fa fa-envelope" style="color: black;"><a href="mailto: excellar07@gmail.com">  excellar07@gmail.com</a> </i>
          </div>

          <div class="col-md-4 pl-2 pr-2 pb-2">
            <div class="embed-responsive embed-responsive-16by9">
              <iframe class="embed-responsive-item" style="border-radius: 10px;" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15074.800657302118!2d73.0274419!3d19.1645977!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x3ce159e9c8cb2a2b!2sExcellar%20Healthcare%20Pvt.%20Ltd.!5e0!3m2!1sen!2sin!4v1620639172694!5m2!1sen!2sin" frameborder="0"></iframe>
            </div>
          </div>
      </div>
  </div>
  <div class="footer-copyright">
      <div class="container-copyright">
          © 2022 Copyright  <a routerLink="/home" style="color: skyblue;"> Excellar Healthcare Pvt.Ltd  </a>  All rights reserved 
      </div>
  </div>
</footer>