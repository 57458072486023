export const veterinaryProductData = [
    {
        "brandId": 1,
        "brandName": "ACIPHOS Injection",
        "active_ingredient": ["Each ml. contains : Sodium Acid Phosphate I.P. 40% w/v","Sodium Methyl Paraben I.P. 0.1% w/v","Water For Injection I.P.q.s."],
        "theropetic_segment": ['NUTRICEUTICAL'],
        "pack_details": ["10 ml/ 30 ml/ 100 ml"]
    },
    {
        "brandId": 2,
        "brandName": "ACIPHOS FORT Injection",
        "active_ingredient": ["Each ml contains : Sodium Acid Phosphate IP 400 mg.","Methylcobalarnin 100 mg.","Water for injection IP q.s."],
        "theropetic_segment": ['NUTRICEUTICAL'],
        "pack_details": ["30 ml/ 100 ml"]
    },
    {
        "brandId": 3,
        "brandName": "ASWI-K Injection",
        "active_ingredient": ["Each Vial Contains : Cefoperazone Sodium I.P.( sterile ) equivalent to cefoperazone : 4000 mg / 3000 mg","Tazobactam Sodium I.P. ( sterile ) equivalent to tazobactam : 500 mg /375 mg"],
        "theropetic_segment": ['ANTIBIOTIC'],
        "pack_details": ["4.5 gm /3.375 gm "]
    },
    {
        "brandId": 4,
        "brandName": "BLOATINORM BOLUS",
        "active_ingredient": ["Each Bolus Contains : Simethicone I.P. 150 mg.","Dried Aluminium Hydroxide I.P. 600 mg.","Magnesium Hydroxide I.P. 1200 mg.","Algenic Acid I.P. 300 mg."],
        "theropetic_segment": ['ANTIBLOAT'],
        "pack_details": ["6's"]
    },
    {
        "brandId": 5,
        "brandName": "BLOATINORM-L Liquid",
        "active_ingredient": ["Each 10 ml contains : Dried Aluminium Hydroxide I.P. 600 mg.","Magnesium Hydroxide I.P. 300 mg","Simethicone I.P. 400 mg.","Sorbitol Solution 70% I.P. 100 mg.(Non Crystallising)","Dill Oil B.P. 50 mg.","Flavoured based q.s.","Colour Erythrosine"],
        "theropetic_segment": ['ANTIBLOT'],
        "pack_details": ["100 ml"]
    },
    {
        "brandId": 6,
        "brandName": "CEFAVET Injection",
        "active_ingredient": ["Each Vial Contains : Ceftriaxone Sodium I.P. (Sterile) equivalent to Ceftriaxone 500mg/3000 mg / 4000 mg"],
        "theropetic_segment": ['ANTIBIOTIC'],
        "pack_details": ["500 mg/ 3gm/ 4gm"]
    },
    {
        "brandId": 7,
        "brandName": "CEF-XLR POWDER",
        "active_ingredient": ["Each gram contains: Cephalexin IP Equivalent to anhydrous Cephalexin 75 mg. Excipients q.s."],
        "theropetic_segment": ['ANTIBIOTIC'],
        "pack_details": ["20 gm & 200 gm"]
    },
    {
        "brandId": 8,
        "brandName": "CYCLIN-DT BOLUS",
        "active_ingredient": ["Each Bolus Contains : Doxycycline I.P. 300 mg.","Tinidazole I.P. 1500 mg."],
        "theropetic_segment": ['ANTIBIOTIC'],
        "pack_details": ["6's"]
    },
    {
        "brandId": 9,
        "brandName": "DYAK BOLUS",
        "active_ingredient": ["Each DYAK BOLUS CONTAINS : FURAZOLIDONE I.P. 0.5 gm","IDOCHLORHYDROXYOUINOLINE I.P. I gm.","LIGHT KAOLIN I.P. 2 gm. Excipients q.s."],
        "theropetic_segment": ['ANTIDIARRHEAL'],
        "pack_details": ["2's"]
    },
    {
        "brandId": 10,
        "brandName": "ECTO-N Injection",
        "active_ingredient": ["Each ml. contains : Ivermectin I.P. (Vet) 10 mg.","Benzyl Alcohol I.P. (Vet) 1.5% v/v (As preservative)","Propylene Glycol I.P. q.s."],
        "theropetic_segment": ['PARASITICIDE'],
        "pack_details": ["1 ml/ 10 ml/ 50 ml/ 100 ml"]
    },
    {
        "brandId": 11,
        "brandName": "EMOXEL Injection",
        "active_ingredient": ["Each Vial Contains : Amoxycillin Sodium I.P. (Sterile) equivalent to Amoxycillin 300 mg / 2000 mg / 3000 mg .",
        "Sulbactam Sodium U.S.P. (Sterile) equivalent to Sulbactam 200 mg / 1000 mg / 1500 mg."],
        "theropetic_segment": ['ANTIBIOTIC'],
        "pack_details": ["500 mg/ 3 gm / 4.5 gm"]
    }, 
    {
        "brandId": 12,
        "brandName": "EMOXEL - O BOLUS",
        "active_ingredient": ["Each Emoxel-O Bolus Contain - Amoxicillin Trihydrate I.P. Eq. to Amoxicillin 1500 mg Excipients q.s."],
        "theropetic_segment": ['ANTIBIOTIC'],
        "pack_details": ["2's"]
    },
    {
        "brandId": 13,
        "brandName": "ENDECTIN TABLET & BOLUS",
        "active_ingredient": ["Each tablet / bolus contains : Ivermectin 10 mg. / 25 mg. / 80 mg. / 100 mg. Excipients q.s."],
        "theropetic_segment": ['PARASITICIDE'],
        "pack_details": ["10's / 6's / 1's / 6's"]
    },
    {
        "brandId": 14,
        "brandName": "ENDECTIN POWDER",
        "active_ingredient": ["Each gm contains : IVERMECTIN IP (VET.) 1% w/w"],
        "theropetic_segment": ['PARASITICIDE'],
        "pack_details": ["100 gm"]
    },
    {
        "brandId": 15,
        "brandName": "ENDECTIN-FI BOLUS",
        "active_ingredient": ["Each Bolus contains : Fenbendazole 3000 mg"," Ivermectin I.P. (Vet) 100 mg Excipients q.s."],
        "theropetic_segment": ['PARASITICIDE'],
        "pack_details": ["Each unit pack consists of 1 Bolus"]
    },
    {
        "brandId": 16,
        "brandName": "EXACT INJECTION",
        "active_ingredient": ["Each vial contains : Ceftriaxone Sodium I.P. (Sterile) equivalent to Ceftriaxone 200mg/500mg/1500mg/2000mg/3000mg","Sulbactam Sodium U.S.P.(Sterile) equivalent to Sulbactam 125mg/ 200mg/ 750mg /1000mg /1500mg."],
        "theropetic_segment": ['ANTIBIOTIC'],
        "pack_details": ["375mg / 750mg / 2.25gm / 3gm / 4.5gm"]
    },
    {
        "brandId": 17,
        "brandName": "EXIQUIN INJECTION",
        "active_ingredient": ["Each ml contains : Enrofloxacin I.P. (Vet.) 100 mg.","Benzyl Alcohol I.P. (Vet) 1.5% v/v (As Preservative)","Water For Injection  I.P. q.s."],
        "theropetic_segment": ['ANTIBIOTIC'],
        "pack_details": ["30 ml/50 ml"]
    },
    {
        "brandId": 18,
        "brandName": "EXPELL FORTE TAB / BOLUS",
        "active_ingredient": ["Each Tablet / Bolus contains : Albendazole I.P. 150 mg. / 600 mg."],
        "theropetic_segment": ['ANTHELMINTIC'],
        "pack_details": ["10's / 6's"]
    },
    {
        "brandId": 19,
        "brandName": "EXPELL SUSPENSION",
        "active_ingredient": ["Each ml contains Albendazole IP 25 mg"],
        "theropetic_segment": ['ANTHELMINTIC'],
        "pack_details": ["30 ml / 60 ml / 90 ml / 500 ml"]
    },
    {
        "brandId": 20,
        "brandName": "FEROLIV INJECTION",
        "active_ingredient": ["Each ml. contains : Thiamine Hydrochloride I.P. 33.3 mg",
        "Pyridoxine Hydrochloride I.P. 3.3 mg",
        "Cyanocobalamin I.P. 33.3 mg",
        "Niacinamide I.P. 33.3 mg",
        "Riboflavine Sodium Phosphate I.P. 1.66 mg",
        "D-Panthenol I.P. 1.66 mg",
        "Benzyl Alcohol I.P. 0.9% v/v",
        "Water For Injection  I.P. q.s."],
        "theropetic_segment": ['NUTRICEUTICAL'],
        "pack_details": ["10 ml /30 ml /50 ml /100 ml"]
    },
    {
        "brandId": 21,
        "brandName": "FI-HO BOLUS",
        "active_ingredient": ["Each bolus contains Aceclofenac I.P.300 mg","Serratiopeptidase I.P. (1,50,000 Serratiopeptidase units) 75 mg","Paracetamol I.P.  1500 mg ,Excipients q.s."],
        "theropetic_segment": ['ANTIPYRETIC ANTIINFLAMMATORY'],
        "pack_details": ["6's"]
    },
    {
        "brandId": 22,
        "brandName": "FLUKNIDE TABLET",
        "active_ingredient": ["Each uncoated tablet contains : Oxyclozanide I.P. (Vet) 200 mg","Excipients q.s."],
        "theropetic_segment": ['ANTHELMINTIC'],
        "pack_details": ["200 mg Tab 10's"]
    },
    {
        "brandId": 23,
        "brandName": "FLUKNIDE BOLUS",
        "active_ingredient": ["Each uncoated tablet contains : Oxyclozanide I.P. (Vet) 1 gm","Excipients q.s."],
        "theropetic_segment": ['ANTHELMINTIC'],
        "pack_details": ["6's"]
    },
    {
        "brandId": 24,
        "brandName": "FLUKNIDE FORTE TABLET & BOLUS",
        "active_ingredient": ["Each uncoated tablet contains : Oxyclozanide I.P. (Vet) 1 gm","Levamisole Hcl I.P. 0.5 gm"],
        "theropetic_segment": ['ANTHELMINTIC'],
        "pack_details": ["6's"]
    },
    {
        "brandId": 25,
        "brandName": "FLUKNIDE - L DS LIQUID",
        "active_ingredient": ["Each ml contains :Levamisole Hydrochloride I.P. 3% w/v Oxyclozanide I.P. (Vet) 6% w/v"],
        "theropetic_segment": ['ANTHELMINTIC'],
        "pack_details": ["100 ml"]
    },
    {
        "brandId": 26,
        "brandName": "FLUKNIDE - L SUSPENSION LIQUID",
        "active_ingredient": ["Each ml contains :Levamisole Hydrochloride I.P. 1.5% w/v Oxyclozanide I.P. (Vet) 3% w/v"],
        "theropetic_segment": ['ANTHELMINTIC'],
        "pack_details": ["110 ml"]
    },
    {
        "brandId": 27,
        "brandName": "HISTAMIN BOLUS",
        "active_ingredient": ["Each bolus contains : Pheniramine Maleate I.P.500 mg, Excipients q.s."],
        "theropetic_segment": ['ANTHELMINTIC'],
        "pack_details": ["6's"]
    },
    {
        "brandId": 28,
        "brandName": "INFLAWIN BOLUS",
        "active_ingredient": ["Each bolus contains : Serratiopeptidase I.P.30 mg (As Enteric Coated), Excipients q.s."],
        "theropetic_segment": ['ANTI INFLAMMATORY'],
        "pack_details": ["6's"]
    },
    {
        "brandId": 29,
        "brandName": "INFLAWIN FORTE BOLUS",
        "active_ingredient": ["Each bolus contains : Serratiopeptidase I.P.50 mg (As Enteric Coated), Excipients q.s."],
        "theropetic_segment": ['ANTI INFLAMMATORY'],
        "pack_details": ["6's"]
    },
    {
        "brandId": 30,
        "brandName": "KB-D3 INJECTION",
        "active_ingredient": ["Each ml contains : Vitamin-A I.P. 250000 I.U. Vitamin-D3 I.P. 25000 I.U.Vitamin-E (Acetate) I.P.100 I.U. Biotin U.S.P. 12.5 mcg. Benzyl Alcohol I.P. 2% v/v (as preservative) Sesame Oil I.P. q.s.,"],
        "theropetic_segment": ['NEUTRICEUTICAL'],
        "pack_details": ["5"]
    },
    {
        "brandId": 31,
        "brandName": "K-FEN TABLET / BOLUS",
        "active_ingredient": ["Each tablet contain :Fenbendazole I.P.150 mg, Each bolus contain :Fenbendazole I.P. 1500 mg.,"],
        "theropetic_segment": ['ANTHELMINTIC'],
        "pack_details": ["10's/2's"]
    },
    {
        "brandId": 32,
        "brandName": "NBE PLUS BOLUS",
        "active_ingredient": ["Each bolus contain :Aceclofenac I.P.300 mg.Paracetamol I.P. 1500 mg. Excipients q.s.,"],
        "theropetic_segment": ['ANTHELMINTIC'],
        "pack_details": ["10's/2's"]
    },
    {
        "brandId": 33,
        "brandName": "NIDOTRON INJECTION",
        "active_ingredient": ["Each ml contains : Nimesulide B.P. 100 mg.","Paracetamol I.P. 150 mg.","Propylene Glycol I.P. q.s."],
        "theropetic_segment": ['ANALGESIC ANTIFYRETIC'],
        "pack_details": ["15 ml/ 30 ml/ 50 ml/ 100 ml"]
    },
    {
        "brandId": 34,
        "brandName": "OTRIM BOLUS",
        "active_ingredient": ["Each uncoated bolus contains : Sulphamethoxazole I.P. 2000 mg.","Trimethoprime I.P. 400 mg."," Excipients q.s."],
        "theropetic_segment": ['ANALGESIC ANTIFYRETIC'],
        "pack_details": ["6's"]
    },
    {
        "brandId": 35,
        "brandName": "PARALGIN-NP BOLUS",
        "active_ingredient": ["Each bolus contain : Paracetamol I.P. 1500 mg.","Nimesulide B.P. 400 mg."],
        "theropetic_segment": ['ANALGESIC ANTIPYRETIC'],
        "pack_details": ["6's"]
    },

    {
        "brandId": 36,
        "brandName": "PARCEF INFUSSION",
        "active_ingredient": ["Each Bolus contains : Fenbendazole  I.P. 3000 mg","Ivermectin I.P. (Vet) 100 mg Excipients q.s."],
        "theropetic_segment": ['INTRAMAMMARY INFUSION'],
        "pack_details": ["10 ml"]
    },





    {
        "brandId": 37,
        "brandName": "POLY XLR SKIN OINTMENT",
        "active_ingredient": ["Each gram contains : Neomycin Sulphate I.P. 3400 I.U.","Zinc Bacitracin I.P. 400 I.U.","Polymyxin B Sulfate U.S.P. 5000 I.U.","Ointment base q.s."],
        "theropetic_segment": ['ANTIBIOTIC'],
        "pack_details": ["20 gm & 50 gm"]
    },
     {
        "brandId": 38,
        "brandName": "PRO-TZ BOLUS",
        "active_ingredient": ["Each bolus contain : Ciprofloxacin HCL. I.P. Eq. to Ciprofloxacin 1500 mg.","Tinidazole I.P. 1800 mg.","Excipients q.s."],
        "theropetic_segment": ['ANTIBIOTIC ANTI PROTOZOAN'],
        "pack_details": ["2's"]
    }, 
    {
        "brandId": 39,
        "brandName": "PRO-TZ TABLETS",
        "active_ingredient": ["Each bolus contain : Ciprofloxacin HCL. I.P. Eq. to Ciprofloxacin 250 mg.","Tinidazole I.P. 300 mg.","Excipients q.s."],
        "theropetic_segment": ['ANTIBIOTIC ANTI PROTOZOAN'],
        "pack_details": ["10's"]
    },
    {
        "brandId": 40,
        "brandName": "SPASMONIM BOLUS",
        "active_ingredient": ["Each Bolus contains : Dicyclomine Hydrochloride  60 mg. Nimesulide 300 mg.","Excipients q.s."],
        "theropetic_segment": ['ANTICHOLINIRGIC ANALGESIC'],
        "pack_details": ["6's"]
    },
    {
        "brandId": 41,
        "brandName": "VITA-H INJECTION",
        "active_ingredient": ["Each ml contains :Vitamin A I.P.250000 I.U. Vitamin D3 I.P. 25000 I.U. Vitamin E I.P. 100 I.U. Vitamin H U.S.P.12.5 mcg (Biotin) Benzyl Alcohol I.P. 2% v/v Sesma Oil I.P. q.s.","Excipients q.s."],
        "theropetic_segment": ['NUTRICEUTICALS'],
        "pack_details": ["10 ml"]
    },
    {
        "brandId": 42,
        "brandName": "XYCLOFEN INJECTION",
        "active_ingredient": ["Meloxicam I.P.5 mg. Water For Injection I.P. q.s.",],
        "theropetic_segment": ['NSAID'],
        "pack_details": ["30 ml"]
    },
    
    {
        "brandId": 43,
        "brandName": "XYCLOFEN PLUS INJECTION",
        "active_ingredient": ["Each ml. contains : Benzyl Alcohol I.P. 2.0%v/v (as preservative)","Water For Injection I.P. q.s."],
        "theropetic_segment": ['ANTI CHOLINERGIC'],
        "pack_details": ["30 ml/ 100 ml"]
    },
    {
        "brandId": 44,
        "brandName": "XLT 4% INJECTION",
        "active_ingredient": ["Each ml. contains : Antimony Potassium Tartrate U.S.P. 40 mg.","Chlorocresol I.P. (As Preservative ) 0.1%w/v","Water For Injection I.P. q.s."],
        "theropetic_segment": ['EMETIC TARTAR'],
        "pack_details": ["30 ml"]
    },
    {
        "brandId": 45,
        "brandName": "XLR-AB CREAM",
        "active_ingredient": ["Gamma Benzene Hexachloride IP 0.1% w/w","Profavin Hemissulphate I.P. 0.1% w/w","Strong Cetrimide Solution I.P. 0.45 w/w"],
        "theropetic_segment": ['MAGGOTICIDE'],
        "pack_details": ["30 gm"]
    },
    {
        "brandId": 46,
        "brandName": "XLR-NPC BOLUS",
        "active_ingredient": ["Each Bolus contains:Nitrofurazone LP 60 mg","Urea I.P. 6 gm"],
        "theropetic_segment": ['ANTIBIOTIC'],
        "pack_details": ["4's"]
    }
]