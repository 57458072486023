export const humanProductData = [
    {
        "brandId": 1,
        "brandName": "K.C. + -H TABLET",
        "active_ingredient": ["Each film coated tablet contains: Azithromycin I.P.500mg", "Excipients q.s."],
        "theropetic_segment": ['Azithromycin Tablets I.P.'],
        "pack_details": ["1x3"]
    },
    {
        "brandId": 2,
        "brandName": "POLY XLR- H PLUS CREAM",
        "active_ingredient": ["Luliconazole 1% w/w", "Cream Base q.s."],
        "theropetic_segment": ["Luliconazole Cream 1%w/w"],
        "pack_details": ["10gm"]
    },
    {
        "brandId": 3,
        "brandName": "MINEREX D3 DROP",
        "active_ingredient": ["Other ingredients Sucrose Sorbitol, propylene Glycol, Sodium Hydroxide"],
        "theropetic_segment": ["Multi-Vitamin & Multi-Mineral with Cholecalciferol Drop"],
        "pack_details": ["15ml"]
    },
    {
        "brandId": 4,
        "brandName": "MINEREX -A SYRUP",
        "active_ingredient": ["Each 15 ml contains: (approx.) I-Arginine Hcl 13.28 mg.", "Histidine Hcl 3.71 mg.", "Leucine 18.3 mg.", "Isoleucine 5.9 mg.", "L-Lysine 25 mg.","Phenylalanine 5 mg.","Threonine 4.2 mg.","Valine 6.7 mg.","Tryptophan 5 mg.","Methionine 9.2 mg.","Cholecalciferol 800 IU.","Nicotinamide 25 mg.","Pyridoxine Hcl 1.5 mg.","Beta Carotene 2.5% 38 mcg.","Methylcobalamin 100 mcg.","Zinc 3 mg.","Potassium 50 mcg.","Calcium 100 mg.","Sorbitol Base q.s."],
        "theropetic_segment": ["Multi-Vitamin & Multi-Mineral with Amino Acid"],
        "pack_details": ["200ml"]
    },
    {
        "brandId": 5,
        "brandName": "HISTAMIN- H PLUS TABLET",
        "active_ingredient": ["Each film coted tablet contains: Montelukast Sodium IP","Equivalent to Montelukast 10 mg.","Levocetirizine Dihydrochloride IP 5 mg.","Excipients q.s."],
        "theropetic_segment": ["Montelukast Sodium & Levocetirizine","Dihydrochloride Tablets I.P."],
        "pack_details": ["10x10 Tab"]
    },
    {
        "brandId": 6,
        "brandName": "AMPIXEL-625 TABLET",
        "active_ingredient": ["Each film coated tablet contains: Amoxycillin Trihydrate IP", "eq.  To Amoxycillin 500 mg.","Potassium Clavulanate Diluted IP ","eq.  To Clavulanate Acid 125 mg.","Excipients q.s."],
        "theropetic_segment": [" Amoxycillin and Potassium Clavulanate Tablet IP"],
        "pack_details": ["1x10 Tab"]
    },
    {
        "brandId": 7,
        "brandName": "BFG-H TABLET",
        "active_ingredient": ["Each hard gelatin capsule contains: Pantoprazole Sodium I.P.","equivalent to Pantoprazole 40 mg.(As enteric coated pellets)","Domperidone I.P. 30 mg. (As sustained release Pellets)"],
        "theropetic_segment": ["Pantoprazole Sodium (EC) & Domperidone (SR) Capsules IP"],
        "pack_details": ["10x10 Tab"]
    },
    {
        "brandId": 8,
        "brandName": "NBE PLUS-H TABLET",
        "active_ingredient": ["Each film coated tablet contains: Aceclofenac IP 100 mg ","Paracetamol IP 325 mg.","Excipients q.s."],
        "theropetic_segment": ["Aceclofenac & Paracetamol Tablets"],
        "pack_details": ["10x10 Tab"]
    },   
    {
        "brandId": 9,
        "brandName": "AMPIXEL-DS DRY SYRUP",
        "active_ingredient": [" Each 5ml of reconstituted suspension contains: Amoxycillin Trihydrate IP","eq. to Anhydrous Amoxycillin 400 mg.","Potassium Clavulanate Diluted IP ","Eq.  To Clavulanate Acid 57 mg.","Excipients q.s."],
        "theropetic_segment": ["Amoxycillin and Potassium Clavulanate Oral Suspension IP "],
        "pack_details": ["7 gm/30ml"]
    },
    {
        "brandId": 10,
        "brandName": "FEROLIV-XT TABLET",
        "active_ingredient": ["Each film coated tablet contains: Ferrous Ascorbate","Eq. to Elemental Iron 100 mg.","Folic Acid IP 1.5 mg.","Zinc Sulphate Monohydrate IP 61.8 mg.","Eq. to Elemental Zinc 22.5 mg."],
        "theropetic_segment": ["Ferrous Ascorbate, Folic Acid & Zinc Sulphate Tablets"],
        "pack_details": ["1x10 Tab"]
    },
    {
        "brandId": 11, 
        "brandName": "EDOZY -OZ TABLET",
        "active_ingredient": ["Each film coated tablet contains: Ofloxacin I.P. 200 mg.","Ornidazole I.P. 500 mg.","Excipients q.s."],
        "theropetic_segment": ["Ofloxacin & Ornidazole Tablets I.P."],
        "pack_details": ["10x10 Tab"]
    },
    {
        "brandId": 12,
        "brandName": "DURAPRO-H CAPSULES",
        "active_ingredient": ["Each hard gelatin capsul contains: Rabeprazole Sodium I.P. 20 mg. (As enteric coated pellets)","Levosulpiride 75 mg. (As sustained release Pellets)"],
        "theropetic_segment": ["Enteric Coated Rabeprazole Sodium & Levosulpiride (SR) Capsule"],
        "pack_details": ["10x10 Tab"]
    },
    {
        "brandId": 13,
        "brandName": "NBE PLUS GEL",
        "active_ingredient": ["Diclofenac Diethylamine I.P. 1.16% w/w (equivalent to Diclofenac Sodium I.P. 1.00% w/w)","Linseed Oil B.P. 3.00% w/w","Menthol I.P. 5.00% w/w","Methyl Salicylate I.P. 10.00% w/w","Benzyl Alcohol I.P.(as preservative) 1.00% w/w","In gel base q.s."],
        "theropetic_segment": ["Diclofenac Sodium ", "Linseed Oil,","Methyl Salicylate & Menthol Gel"],
        "pack_details": ["30 gm"]
    },
    {
        "brandId": 14,
        "brandName": "CALCINET-L",
        "active_ingredient": ["Each film coated tablet contains: (Approx.) Calcitriol 0.25 mcg.","Calcium Citrate 1000 mg.","Sodium Borate 0.25 mg.","Zinc Sulphate 7.5 mg.","Magnesium Oxide 7.5 mg.","L Methyl Folate 400 mcg.","Methylcobalamin 500 mcg."],
        "theropetic_segment": ["Calcium Citrate, Methyl Folate & Methycobalamin Tablets"],
        "pack_details": ["1x10 Tab"]
    },
    {
        "brandId": 15,
        "brandName": "EXCELL-LS COUGH SYRUP",
        "active_ingredient": ["Each 5 ml cotains: Ambroxol Hydrochloride I.P. 30 mg.","Levosalbutamol Sulphate I.P.","Eq. to Levosalbutamol 1 mg.","Guaiphenesin I.P. 50 mg.","Mentho Flavoured Syrupy base. q.s."],
        "theropetic_segment": ["Levosalbutamol, Ambroxol Hydrochloride & Guaiphenesin Syrup"],
        "pack_details": ["100 ml."]
    }
];