<div>
  <img class="d-block w-100" src="assets/contactus3.png">
</div>
<div class="col-lg-5 col-md-2 col-sm-5 container justify-content-center">
  <!-- <h1 class="contactus"> Contact Us</h1> -->
  <br>
    <form [formGroup]="form" method="post" (ngSubmit)="onSubmit()">
        <div class="form-group">
            <label for="name" style="font-weight:700 ;">Name</label>
            <span class="ninja-forms-req-symbol"> *</span>
            <input type="text" class="form-control" placeholder="Enter Name" id="name" maxlength="255" required="true" formControlName="name" >
          </div>
          <div class="form-group">
            <label for="email" style="font-weight:700 ;">Email</label>
            <span class="ninja-forms-req-symbol"> *</span>
            <input type="email" class="form-control" placeholder="Enter Email" id="email" maxlength="255" required="true" formControlName="email" >
          </div>
        <div class="form-group">
          <label for="message" style="font-weight:700 ;">Message</label>
          <span class="ninja-forms-req-symbol"> *</span>
         <textarea id="message" class="form-control" name="message" placeholder="Write something here..." rows="3" required="true" formControlName="message" ></textarea>
        </div>
        <button type="submit" [disabled]="isLoading"  class="submitbutton">Send Message</button>
        <div *ngIf="issubmitted" class="center-text">
          <br>
          <span [ngStyle] = "{'color': msgcolor}"><h5>{{responseMessage}}</h5></span>
        </div>
      </form>
</div>